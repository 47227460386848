/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import Certifications from "./certifications"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const LongBio = () => {
  const data = useStaticQuery(graphql`
    query LongBioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(height: 100, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
      contentfulWebSiteContent(contentId: {eq: "long-bio"}) {
        contentElement {
          raw
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed
    console.log(avatar)

  // imgStyle={{
  //   borderRadius: `50%`,
  // }}
  return (
    <div className="bio">
      <div className="content">
        {renderRichText(data.contentfulWebSiteContent.contentElement)} 
        <p>
            Software Engineer and Architect.  For the past decade or so, leading teams in building teams and software I am proud of.
        </p>
        <p>
            I have be fortunate enough to have caught the wave on couple of technologies - early cloud adopter(AWS,2009), 
            early-ish non-relational DB adopter(MongoDB, 2014), early Kubernetes adopter(2015).  I have been fortunate to serve and be part
            some excellent team at large organizations and startups, to start an Enterprise Architecture practice and an R\&D team, 
            to consult and to build products, to work with big data and to build mobile apps.
        </p>
        <p>
            Early in my career a wise man observed that no matter what role I play my "source of power" is my technical chops.  And so, 
            I spend a lot of time keeping - learning hands-on - up with technology.  Currently, I am catching up on React and Cloud Native.  
        </p>
      <Certifications/>
        <p>
           <Link to="/contact">Contact me</Link>
        </p>
        <br/>
        
      </div>
    </div>
  )
}

export default LongBio
