import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
//import { node } from 'prop-types'
import { Grid, Tooltip } from '@material-ui/core'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Certifications = ()=> {
    const query = graphql`
    query {
        allContentfulCertification {
          edges {
            node {
              id
              link
              name
              logo {gatsbyImageData}
            }
          }
        }
      }
      
    `
  //   {fluid (maxWidth:200) {
  //     ...GatsbyContentfulFluid
  // }}
      const data = useStaticQuery(query);
      const certifications = data.allContentfulCertification.edges;
    return (
        <>
            <h5>
                Certifications
            </h5>

            <ul style={{display: "flex", listStyle: 'none'}}>
            {// @ts-ignore
            certifications.map( ({node: cert}) => (
                <li>
                 <a href={cert.link} target="_blank">   
                 <Tooltip title={cert.name} >
                 {/* <Img fluid={cert.logo.fluid} style={{width:140}}/> */}
                  <GatsbyImage
                      alt="some alt"
                      image={getImage(cert.logo.gatsbyImageData)!}
                  />
                 </Tooltip>
                 </a>
                </li>
            ))}
            </ul>

            
        </>
    )
}

export default Certifications;