import React from "react"
import { Link, graphql } from "gatsby"

import LongBio from "../components/bio-long"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from "../components/nav"

// @ts-ignore
const BioIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  
    return (
      // @ts-ignore
      <Layout location={location} title={siteTitle}>
        <SEO title="Bio - Vadim Polyakov" />
        <Nav />
        <LongBio />
        {/* <p>
          No blog posts found. Add markdown posts to "content/blog" (or the
          directory you specified for the "gatsby-source-filesystem" plugin in
          gatsby-config.js).
        </p> */}
      </Layout>
    
  )
}

export default BioIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
